// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"xTOPwKmJFAUfePJOJIPqf"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import * as Sentry from "@sentry/nextjs"

Sentry.init({
  dsn: "https://343ceff2d4a7493ecb02b2442e0ec224@o4507660280135680.ingest.us.sentry.io/4507973162696704",

  // Add optional integrations for additional features
  integrations: [Sentry.replayIntegration()],

  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: 1,

  // Define how likely Replay events are sampled.
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // Define how likely Replay events are sampled when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  enabled: false,
  ignoreErrors: [
    "Can't find variable: zaloJSV2",
  ]
})